<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <div>
          <v-card-title v-if="edit">
            Edição do Relatório de Entrega</v-card-title
          >
          <v-card-title v-else> Novo Relatório de Entrega </v-card-title>
          <v-form
            id="form"
            ref="form"
            v-model="valid"
            enctype="multipart/form-data"
            class="pa-3"
            lazy-validation
            @submit.prevent="update"
          >
            <v-card class="ma-1" style="padding-bottom: 20px">
              <v-list-item-content v-if="!edit">
                <v-row class="pa-2">
                  <v-col class="pa-5" cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="consultor"
                      prepend-icon="mdi-account-search"
                      dense
                      autocomplete="autocomplete_off_hack_xfr4!k"
                      item-text="Nome"
                      item-value="id"
                      label="Selecione um Consultor"
                      validate-on-blur
                      clearable
                      :loading="loadingItem.consultor"
                      :disabled="loadingItem.consultor"
                      :items="consultores"
                    />
                  </v-col>

                  <v-col cols="1" sm="1" md="1">
                    <v-row justify="center" align="center">
                      <v-btn
                        elevation="2"
                        icon
                        color="green darken-1"
                        :disabled="!consultor"
                        @click="getRelatorio"
                      >
                        <v-icon color="green darken-1">mdi-magnify</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-card class="ma-2">
                <v-list-item-content v-if="loadingItem.selectConsultor || edit">
                  <v-card-title>Dados do relatório </v-card-title>
                  <v-row class="px-4">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.Titulo"
                        :rules="rules"
                        counter="100"
                        label="Título de entrega *"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="px-4 py-1">
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.Produtores"
                        solo
                        multiple
                        small-chips
                        clearable
                        :items="consultorData.ProdutorFazenda"
                        item-text="nomeProdutor"
                        item-value="CodProdutor"
                        name="produtores"
                        label="Selecione os produtores *"
                        :rules="[v => !!(v && v.length) || '* Obrigatório']"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 8" small class="caption">
                            <span>
                              {{ item.nomeProdutor }}
                            </span>
                          </v-chip>
                          <span v-if="index === 8" class="grey--text caption">
                            (+{{ form.Produtores.length - 8 }} outros)
                          </span>
                        </template>
                        <template slot="prepend-item">
                          <v-btn
                            class="blue white--text"
                            elevation="2"
                            color="blue"
                            block
                            small
                            :loading="loadingItem.produtores"
                            @click="selectAll"
                          >
                            SELECIONAR TODOS PRODUTORES
                          </v-btn>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="px-4">
                    <v-col cols="6" sm="8">
                      <v-textarea
                        v-model="form.Descricao"
                        filled
                        auto-grow
                        rows="4"
                        row-height="30"
                        shaped
                        name="fazenda"
                        label="Descrição de entrega"
                        :rules="requiredRules"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-row
                  v-if="loadingItem.selectConsultor || edit"
                  class="px-8 py-2"
                >
                  <v-btn
                    type="submit"
                    :disabled="salvando"
                    :loading="salvando"
                    :color="!$route.query.id ? 'success' : 'warning'"
                    form="form"
                  >
                    {{ btnNome }}
                  </v-btn>

                  <router-link
                    :disabled="salvando"
                    :to="`/${$user.get().role}/relatorios/entrega`"
                  >
                    <v-btn :disabled="salvando" color="error" class="ml-3">
                      Cancelar
                    </v-btn>
                  </router-link>
                </v-row>
              </v-card>
            </v-card>
          </v-form>
        </div>
      </template>
    </div>
  </v-card>
</template>
<script>
import { ENV_APP } from '../../../../env'
import consultantService from '../../../services/api/consultant'
import { _orderByName } from '../../../utils/formatter'

export default {
  data() {
    return {
      edit: false,
      salvando: false,
      loading: true,
      loadingItem: {
        consultor: true,
        selectConsultor: false,
        produtores: false,
      },
      rules: [
        v => !!v || '* Obrigatório',
        v => v.length <= 100 || 'Máximo 100 caracteres',
      ],
      requiredRules: [v => !!v || '* Obrigatório'],
      valid: false,
      btnNome: !this.$route.query.id ? 'Salvar' : 'Salvar Alterações',
      form: {
        CodEntrega: null,
        DataCriacao: this.moment().toISOString(true).substr(0, 10),
        Descricao: null,
        CodConsultorTecnico: null,
        Produtores: [],
        Titulo: '',
        ProjectID: ENV_APP.ProjectID,
      },

      consultor: '',
      consultores: [],
      disabled: true,
      consultorData: [],
      menuDataIni: false,
    }
  },

  created() {
    this.getConsultores()
    if (this.$route.query.id) {
      this.form.CodEntrega = this.$route.query.id
      this.edit = true
      this.formDados()
    } else {
      this.loadNewForm()
    }
  },
  methods: {
    getConsultores() {
      if (
        this.$user.get().role === 'admin' ||
        this.$user.get().role === 'gestor'
      ) {
        this.api.get.consultores().then(data => {
          this.consultores = _orderByName({
            data,
            key: 'Nome',
          })
          this.loadingItem.consultor = false
          this.$emit('searching', false)
        })
      } else {
        this.loadingItem.consultor = false
      }
    },

    async getRelatorio() {
      this.resetForm()
      this.loadingItem.consultor = true
      this.api.get.consultor(this.consultor).then(data => {
        this.consultorData = data[0]

        let ProdutorFazendaData = []

        this.consultorData.Produtores.forEach(consultor => {
          let produtorfazenda = {}

          produtorfazenda.CodProdutor = consultor.CodProdutor
          produtorfazenda.nomeProdutor = consultor.pessoafisica.Nome
          produtorfazenda.CodFazenda = this.consultorData?.Fazendas?.find(
            fazenda => fazenda.CodProdutor === consultor.CodProdutor,
          )?.CodFazenda

          const fazenda = this.consultorData.Fazendas.find(
            fazenda => fazenda.CodProdutor === consultor.CodProdutor,
          )
          if (fazenda) {
            produtorfazenda.nomeFazenda = fazenda.pessoajuridica.NomeFantasia
            ProdutorFazendaData.push(produtorfazenda)
          }
        })

        ProdutorFazendaData.sort(this.compare)
        this.consultorData.ProdutorFazenda = ProdutorFazendaData
        this.form.CodConsultorTecnico = this.consultorData.CodConsultorTecnico
        this.loadingItem.consultor = false
        this.loadingItem.selectConsultor = true
      })
    },

    compare(nome1, nome2) {
      if (nome1.nomeProdutor < nome2.nomeProdutor) return -1
      if (nome1.nomeProdutor > nome2.nomeProdutor) return 1
      return 0
    },

    update() {
      this.valid = this.$refs.form.validate()

      if (
        this.form.Produtores.length > 0 &&
        this.consultorData?.ProdutorFazenda.length > 0
      ) {
        this.form.Produtores = this.consultorData?.ProdutorFazenda?.filter(
          data => this.form.Produtores.includes(data.CodProdutor),
        )
      }

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        return false
      } else {
        if (this.form.CodEntrega) {
          this.salvando = true
          this.api.update
            .entrega(this.form.CodEntrega, this.form)
            .then(response => {
              this.Swal.fire({
                title: 'Sucesso',
                text: response,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/relatorios/entrega`,
                })
              })
            })
            .catch(() => {
              this.salvando = false
            })
        } else {
          this.salvando = true
          this.api.save
            .entrega(this.form)
            .then(response => {
              this.Swal.fire({
                title: 'Sucesso',
                text: response,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/relatorios/entrega`,
                })
              })
            })
            .catch(() => {
              this.salvando = false
            })
        }
      }
    },
    loadNewForm() {
      this.form.CodEntrega = null
      this.form.DataCriacao = this.moment().toISOString(true).substr(0, 10)
      this.form.Descricao = null
      this.form.CodConsultorTecnico = null
      this.loading = false
    },
    formDados() {
      this.api.get
        .entrega(this.form.CodEntrega)
        .then(response => {
          const { data } = response

          consultantService
            .getAllProdutores({ id: data.CodConsultorTecnico })
            .then(response => {
              this.consultorData.ProdutorFazenda = response.data.map(item => {
                return {
                  CodProdutor: item.CodProdutor,
                  nomeProdutor: item.produtor.Nome,
                  CodFazenda: item.CodFazenda,
                  nomeFazenda: item.pessoajuridica.NomeFantasia,
                }
              })

              this.form.CodConsultorTecnico = data?.CodConsultorTecnico
              this.form.Descricao = data?.Descricao
              this.form.Titulo = data?.Titulo
              this.form.Produtores = data?.Produtores

              this.loading = false
              this.valid = true
            })
            .catch(() => {
              this.loading = false
              this.valid = true

              this.Toast().fire({
                icon: 'error',
                title: 'Erro ao carregar dados do consultor',
              })

              this.$router.push({
                path: `/${this.$user.get().role}/relatorios/entrega`,
              })
            })
        })
        .catch(() => {
          this.loading = false
          this.valid = true

          this.Toast().fire({
            icon: 'error',
            title: 'Erro ao carregar dados do relatório',
          })

          this.$router.push({
            path: `/${this.$user.get().role}/relatorios/entrega`,
          })
        })
    },

    selectAll() {
      this.form.Produtores = this.consultorData.ProdutorFazenda
      // this.update = true
    },

    resetForm() {
      this.form.Produtores = []
      this.form.Descricao = ''
    },
  },
}
</script>
